
// @ts-nocheck


export const localeCodes =  [
  "en",
  "es",
  "ja",
  "pt",
  "ar",
  "fr",
  "de",
  "tr",
  "hi",
  "ko",
  "ru",
  "tl",
  "th",
  "ms"
]

export const localeLoaders = {
  "en": [],
  "es": [],
  "ja": [],
  "pt": [],
  "ar": [],
  "fr": [],
  "de": [],
  "tr": [],
  "hi": [],
  "ko": [],
  "ru": [],
  "tl": [],
  "th": [],
  "ms": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "dir": "ltr",
      "language": "en"
    },
    {
      "code": "es",
      "dir": "ltr",
      "language": "es"
    },
    {
      "code": "ja",
      "dir": "ltr",
      "language": "ja"
    },
    {
      "code": "pt",
      "dir": "ltr",
      "language": "pt"
    },
    {
      "code": "ar",
      "dir": "rtl",
      "language": "ar"
    },
    {
      "code": "fr",
      "dir": "ltr",
      "language": "fr"
    },
    {
      "code": "de",
      "dir": "ltr",
      "language": "de"
    },
    {
      "code": "tr",
      "dir": "ltr",
      "language": "tr"
    },
    {
      "code": "hi",
      "dir": "ltr",
      "language": "hi"
    },
    {
      "code": "ko",
      "dir": "ltr",
      "language": "ko"
    },
    {
      "code": "ru",
      "dir": "ltr",
      "language": "ru"
    },
    {
      "code": "tl",
      "dir": "ltr",
      "language": "tl"
    },
    {
      "code": "th",
      "dir": "ltr",
      "language": "th"
    },
    {
      "code": "ms",
      "dir": "ltr",
      "language": "ms"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://app.tweetdeleter.com",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "features/index": {
      "de": "/funktionen",
      "es": "/funciones",
      "ja": "/kino",
      "pt": "/funcionalidades",
      "tr": "/ozellikler",
      "th": "/khunsmbati",
      "ar": "/al-mayeezat",
      "fr": "/fonctionnalites",
      "ko": "/gineung",
      "hi": "/suvidhaen",
      "ru": "/funkcii",
      "ms": "/ciri-ciri",
      "en": "/features",
      "tl": "/mga-feature"
    },
    "features/[featureSlug]": {
      "de": "/funktionen/[featureSlug]",
      "es": "/funciones/[featureSlug]",
      "ja": "/kino/[featureSlug]",
      "pt": "/funcionalidades/[featureSlug]",
      "tr": "/ozellikler/[featureSlug]",
      "th": "/khunsmbati/[featureSlug]",
      "ar": "/almizat/[featureSlug]",
      "fr": "/fonctionnalites/[featureSlug]",
      "ko": "/gineung/[featureSlug]",
      "hi": "/suvidhaen/[featureSlug]",
      "ru": "/funkcii/[featureSlug]",
      "ms": "/ciri-ciri/[featureSlug]",
      "en": "/features/[featureSlug]",
      "tl": "/mga-feature/[featureSlug]"
    },
    "pricing": {
      "de": "/preise",
      "es": "/precios",
      "ja": "/ryokin-puran",
      "pt": "/preco",
      "tr": "/fiyatlandirma",
      "th": "/rakha",
      "ar": "/al-asaar",
      "fr": "/prix",
      "ko": "/hoebi",
      "hi": "/keemat",
      "ru": "/ceni",
      "ms": "/harga",
      "en": "/pricing",
      "tl": "/presyo"
    },
    "faq": {
      "de": "/haeufig-gestellte-fragen",
      "es": "/preguntas-frecuentes",
      "ja": "/yoku-aru-go-shitsumon",
      "pt": "/perguntas-frequentes",
      "tr": "/sss",
      "th": "/khatham-thi-phb-bxy",
      "ar": "/al-asila-al-mutadawala",
      "fr": "/faq",
      "ko": "/jaju-mudneun-jilmun",
      "hi": "/praay-poochhe-jaane-vaale-prashna",
      "ru": "/voprosi-i-otveti",
      "ms": "/soalan-lazim",
      "en": "/faq",
      "tl": "/faq"
    },
    "about-us": {
      "de": "/ueber-uns",
      "es": "/conocenos",
      "ja": "/tweetdeleter-ni-tsuite",
      "pt": "/sobre-nos",
      "tr": "/hakkimizda",
      "th": "/keiyw-kab-rea",
      "ar": "/nabtha-aana",
      "fr": "/qui-nous-sommes",
      "ko": "/ulie-daehae",
      "hi": "/hamaare-baare-mein",
      "ru": "/o-nas",
      "ms": "/perihal-kami",
      "en": "/about-us",
      "tl": "/tungkol-sa-amin"
    },
    "contact-us": {
      "de": "/kontakt",
      "es": "/contacto",
      "ja": "/o-toiawase",
      "pt": "/fala-connosco",
      "tr": "/iletisim",
      "th": "/tidtx-rea",
      "ar": "/etasil-bina",
      "fr": "/nous-contacter",
      "ko": "/mun-uihagi",
      "hi": "/hamase-sampark-karen",
      "ru": "/obratitsya-k-nam",
      "ms": "/hubungi-kami",
      "en": "/contact-us",
      "tl": "/kontakin-kami"
    },
    "terms-of-service": {
      "de": "/nutzungsbedingungen",
      "es": "/condiciones-del-servicio",
      "ja": "/sabisu-riyo-kiyaku",
      "pt": "/termos-do-servico",
      "tr": "/kullanim-sartlari",
      "th": "/ngeuxnkhi-kar-hibrikar",
      "ar": "/shuroot-al-istikhdam",
      "fr": "/conditions-dutilisation",
      "ko": "/seobiseu-yaggwan",
      "hi": "/seva-kee-sharten",
      "ru": "/usloviya-ispolzovaniya",
      "ms": "/terma-perkhidmatan",
      "en": "/terms-of-service",
      "tl": "/mga-tuntunin-ng-serbisyo"
    },
    "blog/index": {
      "de": "/blog",
      "es": "/blog",
      "ja": "/blog",
      "pt": "/blog",
      "tr": "/blog",
      "th": "/blog",
      "ar": "/blog",
      "fr": "/blog",
      "ko": "/blog",
      "hi": "/blog",
      "ru": "/blog",
      "ms": "/blog",
      "en": "/blog",
      "tl": "/blog"
    },
    "blog/[slug]": {
      "de": "/blog/[slug]",
      "es": "/blog/[slug]",
      "ja": "/blog/[slug]",
      "pt": "/blog/[slug]",
      "tr": "/blog/[slug]",
      "th": "/blog/[slug]",
      "ar": "/blog/[slug]",
      "fr": "/blog/[slug]",
      "ko": "/blog/[slug]",
      "hi": "/blog/[slug]",
      "ru": "/blog/[slug]",
      "ms": "/blog/[slug]",
      "en": "/blog/[slug]",
      "tl": "/blog/[slug]"
    },
    "checkout/[planId]": {
      "de": "/checkout/[planId]",
      "es": "/comprar/[planId]",
      "ja": "/chekkuauto/[planId]",
      "pt": "/checkout/[planId]",
      "tr": "/checkout/[planId]",
      "th": "/checkout/[planId]",
      "ar": "/checkout/[planId]",
      "fr": "/checkout/[planId]",
      "ko": "/checkout/[planId]",
      "hi": "/checkout/[planId]",
      "ru": "/checkout/[planId]",
      "ms": "/checkout/[planId]",
      "en": "/checkout/[planId]",
      "tl": "/checkout/[planId]"
    },
    "checkout/thank-you": {
      "de": "/checkout/thank-you",
      "es": "/comprar/gracias",
      "ja": "/chekkuauto/arigatogozaimasu",
      "pt": "/checkout/thank-you",
      "tr": "/checkout/thank-you",
      "th": "/checkout/thank-you",
      "ar": "/checkout/thank-you",
      "fr": "/checkout/thank-you",
      "ko": "/checkout/thank-you",
      "hi": "/checkout/thank-you",
      "ru": "/checkout/thank-you",
      "ms": "/checkout/thank-you",
      "en": "/checkout/thank-you",
      "tl": "/checkout/thank-you"
    },
    "upload-archive/[pageId]": {
      "de": "/upload-archive/[pageId]",
      "es": "/upload-archive/[pageId]",
      "ja": "/upload-archive/[pageId]",
      "pt": "/upload-archive/[pageId]",
      "tr": "/upload-archive/[pageId]",
      "th": "/upload-archive/[pageId]",
      "ar": "/upload-archive/[pageId]",
      "fr": "/upload-archive/[pageId]",
      "ko": "/upload-archive/[pageId]",
      "hi": "/upload-archive/[pageId]",
      "ru": "/upload-archive/[pageId]",
      "ms": "/upload-archive/[pageId]",
      "en": "/upload-archive/[pageId]",
      "tl": "/upload-archive/[pageId]"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "dir": "ltr",
    "language": "en",
    "files": []
  },
  {
    "code": "es",
    "dir": "ltr",
    "language": "es",
    "files": []
  },
  {
    "code": "ja",
    "dir": "ltr",
    "language": "ja",
    "files": []
  },
  {
    "code": "pt",
    "dir": "ltr",
    "language": "pt",
    "files": []
  },
  {
    "code": "ar",
    "dir": "rtl",
    "language": "ar",
    "files": []
  },
  {
    "code": "fr",
    "dir": "ltr",
    "language": "fr",
    "files": []
  },
  {
    "code": "de",
    "dir": "ltr",
    "language": "de",
    "files": []
  },
  {
    "code": "tr",
    "dir": "ltr",
    "language": "tr",
    "files": []
  },
  {
    "code": "hi",
    "dir": "ltr",
    "language": "hi",
    "files": []
  },
  {
    "code": "ko",
    "dir": "ltr",
    "language": "ko",
    "files": []
  },
  {
    "code": "ru",
    "dir": "ltr",
    "language": "ru",
    "files": []
  },
  {
    "code": "tl",
    "dir": "ltr",
    "language": "tl",
    "files": []
  },
  {
    "code": "th",
    "dir": "ltr",
    "language": "th",
    "files": []
  },
  {
    "code": "ms",
    "dir": "ltr",
    "language": "ms",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
