import { type BskyPost, type GetPostsFilters } from "~/types/bsky/posts";
import { bskyApi } from "../utilities";

export const getBskyPosts = async (filters: GetPostsFilters = {}) => {
  const params = convertPostFiltersToParams(filters);
  return bskyApi<BskyPost[]>(`/api/v1/secure/bsky/posts?${params.toString()}`);
};

const convertPostFiltersToParams = (filters: GetPostsFilters) => {
  const params = new URLSearchParams();
  for (const key in filters) {
    if (Array.isArray(filters[key]) && filters[key].length > 0) {
      params.set(key, filters[key].join(","));
    } else if (filters[key] !== undefined) {
      params.set(key, filters[key].toString());
    }
  }
  return params;
};

export const deleteBskyPosts = async (ids: number[]) => {
  return bskyApi("/api/v1/secure/bsky/posts/removal", {
    method: "POST",
    body: JSON.stringify({ ids }),
  });
};

export const reloadBskyPosts = async () => {
  return bskyApi("/api/v1/secure/bsky/posts/reload", {
    method: "POST",
  });
};
