import { reloadBskyPosts } from "~/api/bsky/posts";
import {
  fetchResources,
  fetchTranslations,
} from "~/resources/pageResourceHelper";
import store from "~/store";
import { useBsky } from "~/store/modules/bsky";
import type { TwitterSession } from "~/types/session";

const publicPaths = ["/connect/bluesky"];

export default defineNuxtRouteMiddleware(async (to, from) => {
  const config = useRuntimeConfig().public;

  if (
    to.meta.isPublic &&
    config.PUBLIC_URL !== config.BASE_URL &&
    import.meta.client
  ) {
    location.href = config.PUBLIC_URL + to.path;
    return abortNavigation();
  }

  // Redirect /dashboard -> /dashboard/search-and-delete
  const blueskyPathRegex = /^\/bsky(\/dashboard)?\/?$/;
  const twitterPathRegex = /^\/dashboard\/?$/;
  if (blueskyPathRegex.test(to.path)) {
    return navigateTo("/bsky/dashboard/search-and-delete");
  } else if (twitterPathRegex.test(to.path)) {
    return navigateTo("/dashboard/search-and-delete");
  }

  await fetchTranslations(to);
  await fetchResources(to);

  const session = store.state.session as TwitterSession | null;
  const bskySession = useBsky().session;

  if (
    !session?.authenticated &&
    !bskySession?.authenticated &&
    !publicPaths.some((path) => to.path.includes(path)) &&
    import.meta.client
  ) {
    location.href = config.PUBLIC_URL;
    return abortNavigation();
  }

  const blueskyConnectPathRegex = /^\/connect\/bluesky\/?/;
  if (bskySession?.authenticated && blueskyConnectPathRegex.test(to.path)) {
    return navigateTo("/bsky/dashboard/search-and-delete", { external: true }); // Do full reload to avoid issues with pre dashboard showing up on bsky side.
  }

  if (
    !session?.authenticated &&
    bskySession?.authenticated &&
    to.path.startsWith("/dashboard")
  ) {
    return navigateTo("/bsky/dashboard/search-and-delete", { external: true });
  }

  if (
    session?.authenticated &&
    !bskySession?.authenticated &&
    to.path.startsWith("/bsky")
  ) {
    return navigateTo("/dashboard/search-and-delete", { external: true });
  }

  if (
    bskySession?.authenticated &&
    bskySession?.account?.has_deletion_plan &&
    to.path.startsWith("/bsky/dashboard/search-and-delete")
  ) {
    await reloadBskyPosts();
  }
});
