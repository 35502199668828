import { defineStore } from "pinia";

interface WaitState {
  tags: string[];
}

export const useWait = defineStore("wait", {
  state: () =>
    ({
      tags: [],
    }) as WaitState,
  actions: {
    start(tag: string) {
      this.tags.push(tag);
    },
    end(tag: string) {
      this.tags = this.tags.filter((t) => t !== tag);
    },
    is(tag: string) {
      return this.tags.includes(tag);
    },
  },
});
