import type {
  BskyBillingPortalSessionCreateData,
  BskyBillingPortalSessionResponse,
  BskyCheckoutSessionCreateData,
  BskyCheckoutSessionResponse,
  BskyPrice,
} from "~/types/bsky/accounts";
import { bskyApi } from "../utilities";

export const getBskyPrices = async () => {
  return bskyApi<BskyPrice[]>("/api/v1/accounts/prices");
};

export const createBskyCheckoutSession = async ({
  priceId,
  successPath,
  cancelPath,
}: BskyCheckoutSessionCreateData) => {
  return bskyApi<BskyCheckoutSessionResponse>(
    "/api/v1/secure/accounts/checkout",
    {
      method: "POST",
      body: JSON.stringify({
        checkout: {
          price_id: priceId,
          success_path: successPath,
          cancel_path: cancelPath,
        },
      }),
    }
  );
};

export const createBskyBillingPortalSession = async ({
  returnPath,
}: BskyBillingPortalSessionCreateData) => {
  return bskyApi<BskyBillingPortalSessionResponse>(
    "/api/v1/secure/accounts/billing_portal",
    {
      method: "POST",
      body: JSON.stringify({
        return_path: returnPath,
      }),
    }
  );
};
