import { defineStore } from "pinia";
import { deleteBskyPosts, getBskyPosts } from "~/api/bsky/posts";
import { getBskySession } from "~/api/bsky/session";
import type { BskyPost } from "~/types/bsky/posts";
import type { BskySession } from "~/types/bsky/session";
import { getSession, getUser } from "~/api";
import { connectTweetdeleter, generateConnectCode } from "~/api/connect";
import type { BskyPrice } from "~/types/bsky/accounts";
import { getBskyPrices } from "~/api/bsky/accounts";
import { useWait } from "./wait";
import store from "..";
import type { SortState } from "~/types/sort";

export const useBsky = defineStore("bsky", {
  state: () => ({
    session: null as BskySession | null,
    pageSize: 20,
    posts: [] as BskyPost[],
    postSelectionState: {} as Record<number, boolean>,
    postSortState: "newest_first" as SortState,
    prices: null as BskyPrice[] | null,
  }),
  getters: {
    allPostsLoaded(): boolean {
      return this.posts.length % this.pageSize !== 0;
    },
  },
  actions: {
    async getSession() {
      this.session = await getBskySession();

      if (this.session.authenticated) {
        this.linkTweetdeleter();
      }

      return this.session;
    },
    async getPosts() {
      const waitStore = useWait();
      const options = {
        ...getPostSortOptions(this.postSortState),
        ...store.getters.tweetSearchFilterOptions(),
        timezone_offset: new Date().getTimezoneOffset(),
      };

      waitStore.start("bskyPosts");

      this.posts = await getBskyPosts(options);
      this.postSelectionState = this.posts.reduce((acc, post) => {
        acc[post.id] = this.postSelectionState[post.id] ?? false;

        return acc;
      }, {} as Record<number, boolean>);

      waitStore.end("bskyPosts");

      return this.posts;
    },
    async getMorePosts() {
      if (this.allPostsLoaded) return this.posts;

      const offset = this.posts.length;
      const limit = this.pageSize;
      const waitStore = useWait();
      const options = {
        ...getPostSortOptions(this.postSortState),
        ...store.getters.tweetSearchFilterOptions(),
        timezone_offset: new Date().getTimezoneOffset(),
        offset,
        limit,
      };

      waitStore.start("bskyPosts");

      const posts = await getBskyPosts(options);
      this.posts = this.posts.concat(posts);
      this.postSelectionState = this.posts.reduce((acc, post) => {
        acc[post.id] = this.postSelectionState[post.id] ?? false;

        return acc;
      }, {} as Record<number, boolean>);

      waitStore.end("bskyPosts");

      return this.posts;
    },
    selectPost(post: BskyPost, state: boolean = true) {
      this.postSelectionState[post.id] = state;
    },
    selectAllPosts(state: boolean = true) {
      for (const post of this.posts) {
        this.postSelectionState[post.id] = state;
      }
    },
    setPostSortState(sortState: SortState) {
      this.postSortState = sortState;
    },
    async deleteSelectedPosts() {
      const waitStore = useWait();
      const selectedPosts = this.posts.filter(
        (post) => this.postSelectionState[post.id]
      );

      if (selectedPosts.length === 0) return;

      waitStore.start("deleteBskyPosts");

      const ids = selectedPosts.map((post) => post.id);

      await deleteBskyPosts(ids);
      await this.getPosts();

      waitStore.end("deleteBskyPosts");
    },
    async getPrices() {
      if (!this.prices) {
        this.prices = await getBskyPrices();
      }
      return this.prices;
    },
    async linkTweetdeleter() {
      const user = this.session?.user;

      const isLinked = user?.providers.some(
        (provider) => provider.provider_type === "tweetdeleter"
      );

      if (isLinked) return;

      const tdSession = await getSession();

      if (!tdSession.data.attributes.authenticated) return;

      const tdUser = await getUser();
      const uid = tdUser.data.attributes.uid;
      const { code } = await generateConnectCode();

      await connectTweetdeleter(uid, code);
    },
  },
});
