import { type BskySession, type BskyUser } from "~/types/bsky/session";
import { bskyApi, tdApi } from "./utilities";

export const connectBsky = async (uid: string, pass: string) => {
  return bskyApi<BskyUser>("/api/v1/connect/bsky", {
    method: "POST",
    body: JSON.stringify({ connect: { uid, credentials: { pass } } }),
  });
};

export const connectTweetdeleter = async (uid: string, code: string) => {
  return bskyApi<BskyUser>("/api/v1/connect/tweetdeleter", {
    method: "POST",
    body: JSON.stringify({ connect: { uid, credentials: { code } } }),
  });
};

export const generateConnectCode = async () => {
  return tdApi<{ code: string }>("/connect_api/auth/generate_code", {
    method: "POST",
  });
};
