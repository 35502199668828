export const sortStates = {
  newest_first: "newest_first",
  oldest_first: "oldest_first",
  most_retweets_first: "most_retweets_first",
  least_retweets_first: "least_retweets_first",
  most_likes_first: "most_likes_first",
  least_likes_first: "least_likes_first",
  most_quotes_first: "most_quotes_first",
  least_quotes_first: "least_quotes_first",
};

export type SortState = keyof typeof sortStates;

export type SortDirection = "asc" | "desc";
export type SortBy =
  | "reply_count"
  | "repost_count"
  | "like_count"
  | "quote_count";
export interface SortOptions {
  sort_direction: SortDirection;
  sort_by?: SortBy;
}
