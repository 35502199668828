import { sortStates, type SortOptions, type SortState } from "~/types/sort";

export const getPostSortOptions = (state: SortState): SortOptions => {
  switch (state) {
    case sortStates.newest_first:
      return { sort_direction: "desc" };
    case sortStates.oldest_first:
      return { sort_direction: "asc" };
    case sortStates.most_retweets_first:
      return { sort_by: "repost_count", sort_direction: "desc" };
    case sortStates.least_retweets_first:
      return { sort_by: "repost_count", sort_direction: "asc" };
    case sortStates.most_likes_first:
      return { sort_by: "like_count", sort_direction: "desc" };
    case sortStates.least_likes_first:
      return { sort_by: "like_count", sort_direction: "asc" };
    case sortStates.most_quotes_first:
      return { sort_by: "quote_count", sort_direction: "desc" };
    case sortStates.least_quotes_first:
      return { sort_by: "quote_count", sort_direction: "asc" };
    default:
      return { sort_direction: "desc" };
  }
};
