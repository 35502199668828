import { wait } from "~/resources/wait";
import type { ApiError } from "~/types/api";

const MAX_RETRIES = 3;
const RETRY_TIMEOUT = 2000;

const client = async <T>(
  backendUrl: string,
  path: string,
  options: RequestInit = {},
  retries: number = 0
): Promise<T> => {
  try {
    const res = await fetch(`${backendUrl}${path}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      ...options,
    });

    if (!res.ok) {
      let data: Record<string, unknown> = {};

      try {
        data = await res.json();
      } catch (error) {
        console.error(error);
        console.log("The response is not JSON");
      }

      if (Array.isArray(data?.errors) && data.errors.length > 0) {
        const error = data.errors[0] as ApiError;
        throw createError(error.message, Number(error.status));
      } else if (data.errors && typeof data.errors === "object") {
        const entries = Object.entries(data.errors);
        const error = `${entries[0][0]} ${entries[0][1]}`;
        throw createError(error, Number(res.status));
      }

      console.log(`Status: ${res.status}`, `Status text: ${res.statusText}`);

      throw createError(
        `An error occurred while fetching ${path}: ${
          res.statusText || data.error
        }`,
        res.status
      );
    }

    return res.json();
  } catch (error) {
    console.error(error);
    if (retries < MAX_RETRIES) {
      console.log(`Retrying ${path} after ${retries} retries.`);
      await wait(RETRY_TIMEOUT);
      return client(backendUrl, path, options, retries + 1);
    } else {
      throw error;
    }
  }
};

export const tdApi = async <T>(
  path: string,
  options: RequestInit = {},
  retries: number = 0
): Promise<T> => {
  const backendUrl = useRuntimeConfig().public.BACKEND_URL;
  return client(backendUrl, path, options, retries);
};
export const bskyApi = async <T>(
  path: string,
  options: RequestInit = {},
  retries: number = 0
): Promise<T> => {
  const backendUrl = useRuntimeConfig().public.BSKY_BACKEND_URL;
  return client(backendUrl, path, options, retries);
};

const createError = (message: string, status: number): ApiError => {
  const error = new Error(message) as unknown as ApiError;

  error.status = status;

  return error;
};
